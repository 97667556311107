/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>Resume</h1>
        </div>

        <hr />
        <hr />
        <h3>Objective</h3>
        <hr />
        
        <p className="ignore-firstof-style-modification">
          As I continue my education in preparation for my new career in programming, I am looking for a challenging opportunity to help clarify
          where I will find my niche in such a broad spectrum of opportunities.<br /><br />
        </p>
        
        <hr />
        <hr />
        <h3>Professional Experience</h3>
        <hr />

        <p>
          <strong>Hannover Life Reassurance Company of America</strong><br />
          <em>Orlando, FL</em> - 2006-2013<br />
          IT Application Owner - MoSes (Towers) commercial actuarial application.  Responsible for all aspects of the application:  Coding, Deployment, Hardware.<br /><br />
          Valuation - Extensive spreadsheet development using MS Excel, heavy utilization of macros to automate the processing of related spreadsheets.<br /><br />
          Project Management - Stakeholder, Subject Matter Expert in two major corporate initiatives<br /><br />
          Actuary | Financial Reporting | Project Management - Stakeholder, Subject Matter Expert | Application Management
        </p>

        <p>
          <strong>RGA Reinsurance Company</strong><br />
          <em>Chesterfield, MO</em>  - 1998-2006<br />
          Pricing/Marketing - Spreadsheet and commercial actuarial applications.<br /><br />
          Valuation -Extensive spreadsheet development using MS Excel, heavy utilization of macros to automate the processing of related spreadsheets.<br /><br />
          Project Management - Stakeholder, Subject Matter Expert in major corporate initiative to develop an administration system for the Pacific Rim operations<br /><br />
          Actuary | Market Development in the Pacific Rim | GM of the Hong Kong Office <br />
        </p>

        <p>
          <strong>John Alden Life Insurance Company</strong><br />
          <em>Miami, FL</em>  - 1991-1998<br />
          Extensive spreadsheet development using Quatro Pro, heavy utilization of macros to automate the processing of related spreadsheets<br /><br />
          Actuary | Financial Reporting | Financial Modeling | Detail oriented experience progressing to the bigger picture<br />
        </p>

        <p>
          <strong>Phoenix Mutual Life Insurance Company</strong><br />
          <em>Hartford, CT</em>  - 1986-1991<br />
          Extensive APL programming experience, spreadsheet development using Lotus 1-2-3<br /><br />
          Actuarial Student | Detail oriented experience<br /><br />
        </p>
        
        <hr />
        <hr />
        <h3>Education &amp; Credentials</h3>
        <hr />
        
        <p>
          <strong>Valencia College</strong> – <em>Orlando, FL</em><br />
          Introductory programming and web design courses, 2016<br /><br />

          <strong>Yale University</strong> – <em>New Haven, CT</em><br />
          Bachelor of Arts in Applied Mathematics, 1986<br /><br />

          Former <strong>Associate of the Society of Actuaries</strong>, 1990-2013<br />	
          Former <strong>Member of the American Academy of Actuaries</strong>, 1993-2013<br /><br />
        </p>
        
        <hr />
        <hr />
        <h3>Technical Proficiencies</h3>
        <hr />
        
        <p>
          Learning:<br />
           - JavaScript/React/GatsbyJS (2019-now)<br />
           - PHP/Laravel (2018)<br />
           - Ruby/Ruby on Rails (2017)<br />
           - Java (2016)<br />
           - C# (2016)<br />
           - HTML (2016)<br />
           - CSS (2016)<br />
          Programming Languages – APL, Basic, Fortran, Pascal, VBA<br />
          Towers Watson actuarial modeling tools – MoSes and TAS<br />
          Microsoft Office
        </p>
      </section>
 
      <div className="footer">
        <div className="address">
          Winter Garden, FL &nbsp;34787
        </div>
      </div>

    </div>
  </>
)